import React from "react";
import { Link } from "gatsby";
import { pathToLink } from "../helper";

function random(seed) {
  var x = Math.sin(parseInt(seed, 36)) * 100011;
  return x - Math.floor(x);
}

const generateBg = seed =>
  `hsl(${Math.floor(random(seed) * 700)}, ${Math.floor(
    random(seed) * 70
  )}%, 50%)`;

export default ({ link, name }) => (
  <Link to={pathToLink(link)} key={link}>
    <span
      className="inline-block  rounded-full px-3 py-1 text-sm font-semibold text-white m-1 hover:underline bg-gray-400 hover:bg-gray-500"
      style={{ backgroundColor: generateBg(name) }}
    >
      #{name}
    </span>
  </Link>
);
