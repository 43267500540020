import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
// import contentParser from "gatsby-wpgraphql-inline-images";

import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
import SearchCourse from "../components/SearchCourse";
import WhatsappButton from "../components/WhatsappButton";
import Card from "../components/Card";
import Tag from "../components/Tag";

const Blog = ({ data }) => {
  const posts = data.allWpPost.edges.map(({ node }) => node);
  const tags = data.allWpTag.edges.map(({ node }) => node);
  const categories = data.allWpCategory.edges.map(({ node }) => node);
  const title = "Blog";
  const slug = "blog";

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} />

      <section className="text-gray-700 body-font bg-indigo-100">
        <div className="container px-5 py-4 mx-auto">
          <h1 className="title-font sm:text-6xl text-3xl mb-4 font-medium text-gray-900 font-family-louize tracking-tighter">
            O que você <span className="text-underline">procura?</span>
          </h1>

          <div className="font-family-louize text-black-dark inline-block py-1 px-3 my-4 font-medium sm:text-5xl text-xl font-black my-4">
            Categorias
          </div>

          {categories && (
            <div className="py-4">
              {categories.map(tag => (
                <Tag key={tag.link} {...tag} />
              ))}
            </div>
          )}
          <hr />
          <div className="grid sm:grid-col-2 lg:grid-cols-2 gap-2 mx-auto">
            {posts.map((node, i) => (
              <Card key={node.slug} {...node} className="w-full" />
            ))}
          </div>
        </div>
      </section>

      <WhatsappButton />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allWpPost(limit: 30, sort: { order: DESC, fields: date }) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          slug
          excerpt
          # author {
          #   node {
          #     name
          #     avatar {
          #       url
          #     }
          #   }
          # }
          # tags {
          #   nodes {
          #     name
          #     slug
          #     link
          #   }
          # }
          categories {
            nodes {
              name
              slug
              link
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }

    allWpTag {
      edges {
        node {
          name
          slug
          link
        }
      }
    }

    allWpCategory {
      edges {
        node {
          name
          slug
          link
        }
      }
    }
  }
`;

export default Blog;
